<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import RemoveModal from '@/components/RemoveModal'
import { ManualTransactionService as Service } from '@/services'
import ManualTransactionCreateOrUpdate from '@/views/ManualTransaction/CreateOrUpdate'

export default {
  name: 'ManualBalanceList',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data () {
    return {
      table: {
        filter: [
          {
            name: 'İşlem Kodu',
            field: 'code',
            type: 'input'
          },
          {
            name: 'Tutar',
            field: 'amount',
            type: 'input'
          },
          {
            name: 'Karşılık Tutar',
            field: 'toTotal',
            type: 'input'
          }
        ],
        page: {
          title: 'Manuel İşlemler Listesi',
          icon: 'bank',
          actions: [
            {
              title: 'Manuel İşlem Ekle',
              type: 'success',
              icon: 'plus',
              perm: 'ManualTransaction.Create',
              action: () => {
                this.openModal({
                  component: ManualTransactionCreateOrUpdate,
                  props: { modal: true },
                  close: () => this.$refs.table.loadAsyncData()
                })
              }
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => this.$refs.table.loadAsyncData()
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'type',
              label: 'Tür',
              renderHtml: true,
              viewMethod: val => `<div class="tag is-${val === 'DEPOSIT' ? 'success' : 'info'}">${val === 'DEPOSIT' ? 'Yatırım' : 'Çekim'}</div>`
            },
            {
              field: 'site.name',
              label: 'Site'
            },
            {
              field: 'bankType',
              label: 'Kasa'
            },
            {
              field: 'total',
              label: 'Tutar',
              column: 'row',
              renderHtml:true,
              viewMethod: val => `${this.numberFormat(val.total)} (${val.currency.shortName})`
            },
            {
              field: 'totalTo',
              label: 'Karşılık Tutarı',
              renderHtml:true,
              viewMethod: val => `₺${this.numberFormat(val)}`
            },
            {
              field: 'commission',
              label: 'Komisyon',
              column: 'row',
              renderHtml:true,
              viewMethod: val => `${this.numberFormat(val.commission)} (%${val.commissionRate})`
            },
            {
              field: 'code',
              label: 'İşlem Kodu'
            },
            {
              field: 'description',
              label: 'Açıklama'
            },
            {
              field: 'creator.username',
              label: 'Oluşturan'
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTime)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Düzenle',
                  type: 'success',
                  column: 'row',
                  perm: 'ManualTransaction.Edit',
                  action: data => {
                    this.openModal({
                      component: ManualTransactionCreateOrUpdate,
                      props: { modal: true, id: data.uuid },
                      close: () => this.$refs.table.loadAsyncData()
                    })
                  }
                },
                {
                  column: 'row',
                  title: 'Sil',
                  type: 'danger',
                  perm: 'ManualTransaction.Delete',
                  action: data => {
                    this.openModal({
                      component: RemoveModal,
                      props: {
                        id: data.uuid,
                        name: data.name,
                        service: data.service,
                        type: 'Manuel Bakiye'
                      },
                      close: this.$refs.table.loadAsyncData
                    })
                  }
                }
              ]
            }
          ]
        }
      }
    }
  }
}
</script>
